<template>
  <div>
    <el-card style="margin: 1em 0" shadow="hover">
      <div slot="header">
        <el-link
          type="primary"
          :href="utils.url.scriptdetail(js.id)"
          target="_blank"
        >
          <v-clamp class="has-text-weight-bold is-size-5" :max-lines="1">
            {{ js.scriptName }}
          </v-clamp>
        </el-link>
        <v-clamp
          class="has-text-grey has-text-weight-semibold "
          style="font-size:1em;margin-top:0.25em;"
          :max-lines="3"
        >
          {{ js.description }}
        </v-clamp>
      </div>
      <div>
        <tags :js="js"></tags>
      </div>
    </el-card>
  </div>
</template>

<script>
import utils from "../utils";
import tags from "./scripttags";
import VClamp from "vue-clamp";

export default {
  name: "ScriptListItem",
  components: {
    tags,
    VClamp,
  },
  props: {
    index: Number,
    js: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      utils,
    };
  },

  methods: {},

  computed: {},
};
</script>

<style lang="css"></style>
