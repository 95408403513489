<template>
  <div>
    <item
      v-for="(item, index) in list"
      :key="item.script_id"
      :js="item"
      :index="index"
    >
    </item>
  </div>
</template>

<script>
import item from "./scriptlistitem";
export default {
  components: {
    item,
  },
  name: "ScriptList",

  props: {
    list: Array,
  },

  data() {
    return {};
  },

  methods: {},

  computed: {},
};
</script>

<style lang="css"></style>
