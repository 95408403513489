<template>
  <div id="mainsearch">
    <el-row type="flex" justify="center">
      <el-col :xs="18" :sm="16" :md="14" :lg="12" :xl="10">
        <el-input
          placeholder="请输入关键词"
          v-model="kw"
          class="input-with-select"
        >
          <el-select
            v-model="select"
            style="width:8em"
            slot="prepend"
            placeholder="请选择"
          >
            <el-option label="全部脚本" value="0"></el-option>
            <el-option label="免费脚本" value="1"></el-option>
            <el-option label="部分收费" value="2"></el-option>
            <el-option label="付费脚本" value="3"></el-option>
          </el-select>
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          ></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col
        style="margin-top: 1em"
        :xs="16"
        :sm="14"
        :md="12"
        :lg="10"
        :xl="8"
      >
        <el-alert
          v-show="desp"
          title="搜索失败"
          show-icon
          :description="desp"
          type="error"
        >
        </el-alert>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :xs="18" :sm="16" :md="14" :lg="12" :xl="10" v-loading="loading">
        <jslist :list="list" style="overflow: auto"></jslist>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :xs="18" :sm="16" :md="14" :lg="12" :xl="10">
        <el-pagination
          @current-change="gotoPage"
          :page-size="pagesize"
          layout="total, prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import utils from "./utils";
import axios from "axios";
import jslist from "./script/scriptlist";
export default {
  name: "MainSearch",
  components: {
    jslist,
  },
  props: {},

  data() {
    return {
      pagesize: 10,
      list: [],
      loading: false,
      desp: "",
      select: "0",
      curpage: 1,
      total: 0,
      lastUrl: "",
      kw: "",
    };
  },
  mounted() {
    if (!this.list.length) {
      this.lastUrl = "queryScript";
      axios
        .get(utils.api[this.lastUrl](this.curpage, this.pagesize, this.select))
        .then(this.processData);
    }
  },
  methods: {
    gotoPage(newpage) {
      this.curpage = newpage;
      axios
        .get(
          utils.api[this.lastUrl](
            this.curpage,
            this.pagesize,
            this.select,
            this.kw
          )
        )
        .then(this.processData);
    },
    async search() {
      this.desp = "";
      if (!this.kw) {
        this.$message.error("您还没有输入关键词哦");
      } else {
        this.loading = true;
        this.lastUrl = "queryScriptByName";
        let res = await axios.get(
          utils.api[this.lastUrl](
            this.curpage,
            this.pagesize,
            this.select,
            this.kw
          )
        );
        this.loading = false;
        this.processData(res);
      }
    },
    processData({ data }) {
      if (data.code !== 200) {
        this.desp = data.msg;
      } else {
        this.desp = "";
        if (!(data.data.data instanceof Array) || !data.data.data.length) {
          this.desp = "没有找到您搜索的脚本\n请尝试其他关键词";
        } else {
          data = data.data;
          this.list = data.data;
          this.pageTotalSize = data.pageTotalSize;
          this.total = data.total;
        }
      }
    },
  },

  computed: {},
};
</script>

<style lang="css"></style>
