<template>
  <div id="app">
    <el-container>
      <el-header>
        <navbar></navbar>
      </el-header>
      <el-main
        ><mainsearch></mainsearch>
      </el-main>
      <el-footer>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="6"><div ></div></el-col>
          <el-col :span="6"
            ><div ></div
          ></el-col>
          <el-col :span="6"><div></div></el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import navbar from '../../components/navbar.vue'
import mainsearch from '../../components/mainsearch.vue'
export default {
  components: {
    navbar,
    mainsearch
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style>

</style>
